export async function init(): Promise<void> {
  if (process.env.VUE_APP_LOGROCKET !== "TRUE") return;
  const { default: LogRocket } = await import("logrocket");

  LogRocket.init(process.env.VUE_APP_LOGROCKET_PROJECT_ID, {
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().indexOf("auth/") !== -1) {
          request.body = undefined;
        }
        return request;
      },
    },
  });
}

interface IUserTraits {
  [propName: string]: string | number | boolean;
}

export async function identify(id: string, traits: IUserTraits): Promise<void> {
  if (process.env.VUE_APP_LOGROCKET !== "TRUE") return;
  const { default: LogRocket } = await import("logrocket");

  LogRocket.identify(id, traits);
}

export async function captureException(err: Error): Promise<void> {
  if (process.env.VUE_APP_LOGROCKET !== "TRUE") return;
  const { default: LogRocket } = await import("logrocket");

  LogRocket.captureException(err);
}

/*
// Commented out as couldn't find a way to asynchronously add a plugin to Vuex
export async function createVuexPlugin(): Promise<any | undefined> {
  if (process.env.VUE_APP_LOGROCKET !== "TRUE") return undefined;
  const { default: LogRocket } = await import("logrocket");
  const createPlugin = await import("logrocket-vuex");

  return createPlugin(LogRocket);
}
*/
