














































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";

import AccountSuspendedPopup from "@/components/AccountSuspendedPopup.vue";
import Password from "@/components/inputs/Password.vue";

import i18n from "@/plugins/i18n";

@Component({
  components: {
    AccountSuspendedPopup,
    Password,
  },
})
export default class LoginForm extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;
  username = "";
  password = "";
  usernameRules = [
    (v: unknown): boolean | string => !!v || i18n.t("connection.login.form.validation.login.required").toString(),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  showAccountSuspendedPopup = false;

  async submit(): Promise<void> {
    if (this.$refs.form.validate()) {
      this.sending = true;
      try {
        await this.$store.direct.dispatch.authentication.login({
          login: this.username,
          password: this.password,
        });
        this.$emit("logged");
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("connection.login.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 404)
            this.alert.message = this.$t("connection.login.form.error.code404").toString();
          if (e.response?.status === 403) {
            switch (e.response.data.reason || e.response.data.error.message.reason) {
              case "banned":
                this.alert.message = this.$t("connection.login.form.error.code403.banned").toString();
                break;
              case "deleted":
                this.alert.message = this.$t("connection.login.form.error.code403.deleted").toString();
                break;
              case "validation_expired":
                this.showAccountSuspendedPopup = true;
                break;
              default:
                this.alert.message = this.$t("connection.login.form.error.generic").toString();
                break;
            }
          }
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }

  reset(): void {
    this.$refs.form.reset();
    this.alert.show = false;
  }

  closeShowAccountSuspendedPopup(): void {
    this.showAccountSuspendedPopup = false;
    this.reset();
  }
}
