


























































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import i18n from "@/plugins/i18n";

import WelcomePopup from "@/components/WelcomePopup.vue";

import LoginNew from "@/components/inputs/LoginNew.vue";
import EmailNew from "@/components/inputs/EmailNew.vue";
import Password from "@/components/inputs/Password.vue";
import PasswordNew from "@/components/inputs/PasswordNew.vue";

@Component({
  components: {
    VueHcaptcha,
    WelcomePopup,
    LoginNew,
    EmailNew,
    PasswordNew,
    Password,
  },
})
export default class TheConnectionRegister extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  email = "";
  username = "";
  password = "";
  passwordRepeat = "";

  passwordRepeatRules = [
    (v: string): boolean | string =>
      !!v || i18n.t("connection.register.form.validation.passwordRepeat.required").toString(),
    (_v: string): boolean | string =>
      this.checkPasswordRepeat() || i18n.t("connection.register.form.validation.passwordRepeat.notmatch").toString(),
  ];

  cguAcceptedRules = [
    (v: boolean): boolean | string => !!v || i18n.t("connection.register.form.validation.cgu.notaccepted").toString(),
  ];

  legalTutorAcceptedRules = [
    (v: boolean): boolean | string =>
      !!v || i18n.t("connection.register.form.validation.legalTutor.notaccepted").toString(),
  ];

  captchaToken = "";

  showWelcome = false;

  $refs!: {
    form: HTMLFormElement;
  };

  checkPasswordRepeat(): boolean {
    return this.password === this.passwordRepeat;
  }

  async submit(): Promise<void> {
    if (this.$refs.form.validate() && this.captchaToken) {
      this.sending = true;
      try {
        await this.$store.direct.dispatch.authentication.register({
          email: this.email,
          login: this.username,
          password: this.password,
          captchaToken: this.captchaToken,
        });
        this.showWelcome = true;
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("connection.register.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 403)
            this.alert.message = this.$t("connection.register.form.error.generic").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }

  close(): void {
    this.$store.direct.commit.CLOSE_LOGIN_POPUP({ logged: true });
  }

  get hcaptchaSiteKey(): string {
    return process.env.VUE_APP_HCPATCHA_SITE_KEY;
  }
}
