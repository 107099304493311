



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

import Password from "@/components/inputs/Password.vue";

interface StrengthInfo {
  color: string;
  progress: number;
}

@Component({
  components: {
    Password,
  },
})
export default class PasswordNew extends Vue {
  @Prop(String)
  value = "";

  get password(): string {
    return this.value;
  }

  set password(newValue: string) {
    this.$emit("input", newValue);
  }

  get progress(): number {
    return this.password.length > 0 ? this.strengthInfo.progress : 0;
  }

  rules: Array<(v: string) => boolean | string> = [
    (v: string): boolean | string => !!v || i18n.t("connection.register.form.validation.password.required").toString(),
    (v: string): boolean | string =>
      v.length >= 6 || i18n.t("connection.register.form.validation.password.toosmall").toString(),
  ];

  strengthData: Record<number, StrengthInfo> = {
    0: { color: "red", progress: 33 },
    1: { color: "orange", progress: 66 },
    2: { color: "green", progress: 100 },
  };

  get strength(): number {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(this.password)) return 2;
    if (
      /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[!@#$%^&*]))|((?=.*[a-z])(?=.*[!@#$%^&*]))|((?=.*[0-9])(?=.*[!@#$%^&*])))(?=.{6,})/.test(
        this.password
      )
    )
      return 1;
    return 0;
  }

  get strengthInfo(): StrengthInfo {
    return this.strengthData[this.strength];
  }

  get strengthText(): string {
    if (this.password.length === 0) return "";
    switch (this.strength) {
      case 0:
        return i18n.t("connection.register.form.password.strength.weak").toString();
      case 1:
        return i18n.t("connection.register.form.password.strength.medium").toString();
      case 2:
        return i18n.t("connection.register.form.password.strength.strong").toString();
    }
    return "";
  }
}
