



















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import LoginForm from "@/components/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
})
export default class TheConnectionLogin extends Vue {
  close(): void {
    this.$store.direct.commit.CLOSE_LOGIN_POPUP({ logged: true });
  }
}
