












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";
import { axiosInstance as axios } from "@/store/";

import Email from "@/components/inputs/Email.vue";

@Component({
  components: {
    Email,
  },
})
export default class EmailNew extends Vue {
  @Prop(String)
  value = "";

  get email(): string {
    return this.value;
  }

  set email(newValue: string) {
    this.$emit("input", newValue);
  }
  $refs: {
    input: Email;
  };

  alreadyUsedError = "";
  timeout?: number;
  loading = false;
  checkUsage(): void {
    this.alreadyUsedError = "";

    if (this.$refs.input.rules.some((a) => a(this.email) !== true)) return;
    this.alreadyUsedError = " ";
    this.loading = true;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      try {
        await axios.post(
          `/auth/user/emailvalidity`,
          {
            email: this.email,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        this.alreadyUsedError = "";
      } catch (err) {
        this.alreadyUsedError = i18n.t("connection.register.form.validation.email.alreadyUsed").toString();
      } finally {
        this.loading = false;
      }
    }, 1000);
  }
}
