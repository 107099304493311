import { defineModule } from "direct-vuex";

import { LocaleMessageObject } from "vue-i18n";
import sortBy from "lodash/sortBy";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";

interface CSSPosition {
  right: string;
  top: string;
  left: string;
  bottom: string;
}

interface ButtonsStyles {
  fab: CSSPosition;
  timer: CSSPosition;
}

interface GameLocalizedClientInfo {
  name: string;
  description: string;
  shortDescription: string;
}

enum PegiRating {
  PEGI3 = 3,
  PEGI7 = 7,
  PEGI12 = 12,
  PEGI16 = 16,
  PEGI18 = 18,
}

enum PegiContentDescriptor {
  BAD_LANGUAGE,
  DISCRIMINATION,
  DRUGS,
  FEAR,
  GAMBLING,
  SEX,
  VIOLENCE,
  IN_GAME_PURCHASES,
}

class Pegi {
  rating!: PegiRating;
  contentDescriptors!: PegiContentDescriptor[];
}

class ContentRating {
  pegi!: Pegi;
}

export interface Game {
  _id: string;
  appID: string;
  iconImg: string;
  bannerImg: string;
  name: string;
  orientation: string;
  buttonsStyle: ButtonsStyles;
  fabDirection: string;
  genre: string;
  publisher: string;
  popular: boolean;
  releasedAt: Date;
  contentRating: ContentRating;
  localizedClientInfo: { [key in Locale]: GameLocalizedClientInfo };
  trialTimeout: number;
  screenshots: Screenshot[];
  similarGames: string[];
}

interface GenreLocalizedClientInfo {
  name: string;
  bannerImg: string;
}

type Locale = "fr" | "en" | string;

export class Genre {
  _id: string;
  name: string;
  bannerImg: string;
  bannerImgEn: string;
  headerImg: string;
  localizedClientInfo: { [key in Locale]: GenreLocalizedClientInfo };
}

export interface Publisher {
  _id: string;
  name: string;
}

interface HomeSectionLocalizedClientInfo {
  title: string;
}

export interface HomeSectionGame {
  game: string;
  order: number;
}

export interface HomeSection {
  _id: string;
  name: string;
  link: string;
  games: HomeSectionGame[];
  localizedClientInfo: { [key in Locale]: HomeSectionLocalizedClientInfo };
}

interface GameListLocalizedClientInfo {
  title: string;
}

interface Screenshot {
  url: string;
}

export interface GameList {
  _id: string;
  name: string;
  urlSegment: string;
  games: string[];
  localizedClientInfo: { [key in Locale]: GameListLocalizedClientInfo };
}

let gamesPromise: Promise<AxiosResponse<Game[]>> | undefined = undefined;
let genresPromise: Promise<AxiosResponse<Genre[]>> | undefined = undefined;
let publishersPromise: Promise<AxiosResponse<Publisher[]>> | undefined = undefined;
let homeSectionsPromise: Promise<AxiosResponse<HomeSection[]>> | undefined = undefined;
let gameListsPromise: Promise<AxiosResponse<GameList[]>> | undefined = undefined;

export interface GamesState {
  games: Game[];
  genres: Genre[];
  publishers: Publisher[];
  favoriteGames: string[];
  recentGames: string[];
  homeSections: HomeSection[];
  gameLists: GameList[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    games: [],
    genres: [],
    publishers: [],
    favoriteGames: [],
    recentGames: [],
    homeSections: [],
    gameLists: [],
  } as GamesState,
  getters: {
    getGame: (state) => (appID: string): Game | undefined => state.games.find((g) => g.appID == appID),
    gamesSortedAlpha: (state): Game[] => sortBy(state.games, ["name"]),
    gamesSortedPopular: (state): Game[] =>
      state.games.concat().sort((a, b) => Number(!!b.popular) - Number(!!a.popular)),
    gamesSortedDate: (state): Game[] =>
      state.games.concat().sort((a, b) => new Date(b.releasedAt).getTime() - new Date(a.releasedAt).getTime()),
  },
  mutations: {
    SET_GAMES(state, games: Game[]) {
      state.games = games;
    },
    SET_GENRES(state, genres: Genre[]) {
      state.genres = genres;
    },
    SET_PUBLISHERS(state, publishers: Publisher[]) {
      state.publishers = publishers;
    },
    SET_HOME_SECTIONS(state, homeSections: HomeSection[]) {
      state.homeSections = homeSections;
    },
    SET_GAME_LISTS(state, gameLists: GameList[]) {
      state.gameLists = gameLists;
    },
    SET_FAVORITE_GAMES(state, gameIds: string[]) {
      state.favoriteGames = gameIds;
    },
    ADD_GAME_TO_FAVORITES(state, game: Game) {
      state.favoriteGames.push(game._id);
    },
    REMOVE_GAME_FROM_FAVORITES(state, game: Game) {
      state.favoriteGames.splice(state.favoriteGames.indexOf(game._id), 1);
    },
    SET_RECENT_GAMES(state, gameIds: string[]) {
      state.recentGames = gameIds;
    },
    ADD_RECENT_GAME(state, game: Game) {
      if (state.recentGames.includes(game._id)) state.recentGames.splice(state.recentGames.indexOf(game._id), 1);
      state.recentGames.unshift(game._id);
    },
  },
  actions: {
    async getGames(context): Promise<Game[]> {
      const { rootDispatch, rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.games.length > 0) return state.games;
        if (!gamesPromise) gamesPromise = axios.get<Game[]>(`/api/v1/games`);
        const { data } = await gamesPromise;
        commit.SET_GAMES(data);
        await rootDispatch.language.init();
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.games.forEach((a) => {
            messages[`games.${a._id}.name`] = a?.localizedClientInfo?.[locale]?.name;
            messages[`games.${a._id}.description`] = a?.localizedClientInfo?.[locale]?.description;
            messages[`games.${a._id}.shortDescription`] = a?.localizedClientInfo?.[locale]?.shortDescription;
          });
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.games;
      } catch (error) {
        console.error(error);
        commit.SET_GAMES([]);
        return state.games;
      }
    },
    async getGenres(context): Promise<Genre[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.genres.length > 0) return state.genres;
        if (!genresPromise) genresPromise = axios.get<Genre[]>(`/api/v1/genres`);
        const { data } = await genresPromise;
        commit.SET_GENRES(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.genres.forEach((a) => (messages[`genres.${a._id}.name`] = a?.localizedClientInfo?.[locale]?.name));
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.genres;
      } catch (error) {
        console.error(error);
        commit.SET_GENRES([]);
        return state.genres;
      }
    },
    async getPublishers(context): Promise<Publisher[]> {
      const { commit, state } = moduleActionContext(context, module);
      try {
        if (state.publishers.length > 0) return state.publishers;
        if (!publishersPromise) publishersPromise = axios.get<Publisher[]>(`/api/v1/publishers`);
        const { data } = await publishersPromise;
        commit.SET_PUBLISHERS(data);
        return state.publishers;
      } catch (error) {
        console.error(error);
        commit.SET_PUBLISHERS([]);
        return state.publishers;
      }
    },
    async getHomeSections(context): Promise<HomeSection[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.homeSections.length > 0) return state.homeSections;
        if (!homeSectionsPromise) homeSectionsPromise = axios.get<HomeSection[]>(`/api/v1/homesections`);
        const { data } = await homeSectionsPromise;
        commit.SET_HOME_SECTIONS(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.homeSections.forEach(
            (a) => (messages[`homeSections.${a._id}.title`] = a?.localizedClientInfo?.[locale]?.title)
          );
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.homeSections;
      } catch (error) {
        console.error(error);
        commit.SET_HOME_SECTIONS([]);
        return state.homeSections;
      }
    },
    async getGameLists(context): Promise<GameList[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.gameLists.length > 0) return state.gameLists;
        if (!gameListsPromise) gameListsPromise = axios.get<GameList[]>(`/api/v1/gamelists`);
        const { data } = await gameListsPromise;
        commit.SET_GAME_LISTS(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.gameLists.forEach(
            (a) => (messages[`gameLists.${a._id}.title`] = a?.localizedClientInfo?.[locale]?.title)
          );
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.gameLists;
      } catch (error) {
        console.error(error);
        commit.SET_GAME_LISTS([]);
        return state.gameLists;
      }
    },
    async init(context) {
      const { dispatch } = moduleActionContext(context, module);
      await dispatch.getGames();
      await dispatch.getGenres();
      await dispatch.getPublishers();
      await dispatch.getHomeSections();
      await dispatch.getGameLists();
    },
    async addToFavorite(context, game: Game) {
      const { commit } = moduleActionContext(context, module);
      try {
        commit.ADD_GAME_TO_FAVORITES(game);
        await axios.post(`/api/v1/favorites/${game._id}`);
      } catch (err) {
        commit.REMOVE_GAME_FROM_FAVORITES(game);
      }
    },
    async removeFromFavorite(context, game: Game) {
      const { commit } = moduleActionContext(context, module);
      try {
        commit.REMOVE_GAME_FROM_FAVORITES(game);
        await axios.delete(`/api/v1/favorites/${game._id}`);
      } catch (err) {
        commit.ADD_GAME_TO_FAVORITES(game);
      }
    },
  },
});

export default module;
