











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PlayOverlay extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ type: Function, default: () => {} }) onClick: (e: Event) => void;
  @Prop(String) icon: string;
  @Prop(String) title: string;
  @Prop(String) text: string;
  @Prop(Boolean) opaque: boolean;
}
