/* eslint-disable @typescript-eslint/no-explicit-any */
import store, { axiosInstance as axios } from "../store";

import { ParsedWebRTCStats } from "@/WebRTC/WebRTCStatsParser";

export interface StatJSON {
  // tslint:disable: variable-name
  log_type: string;
  date: string;
  stat_id: string;
  username: string | undefined;
  entrypoint_ip: string;
  url: string;
  isWebApp?: boolean;
}

export interface StatInit extends StatJSON {
  user_agent: string;
  game: string;
}

export interface StatSession extends StatJSON {
  host_id: string;
  webrtc_stats: ParsedWebRTCStats;
  network_score: number;
  webrtc_session_id: string | undefined;
}

export interface StatSpeedTest extends StatJSON {
  speed_test_pass: string;
  speed_test_result: any;
}

export interface StatRedirect extends StatJSON {
  game: string;
  redirect_when: string;
  redirect_store: string;
}

export interface StatLeave extends StatJSON {
  game: string;
  host_id: string;
  reason_of_leave: string;
}

export interface StatNavigation extends StatJSON {
  from: string;
  to: string;
}

interface StatRating extends StatJSON {
  webrtc_session_id: string;
  game: string;
  rating: number;
  issues: string[];
}

export function GetDate(): string {
  const now = new Date();
  const dd = now.getUTCDate();
  const mm = now.getUTCMonth() + 1;
  const yyyy = now.getUTCFullYear();
  const hh = now.getUTCHours();
  const min = now.getUTCMinutes();
  const ss = now.getUTCSeconds();
  const SSS = now.getUTCMilliseconds();

  let timestamp = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
  timestamp = `${timestamp} ${hh < 10 ? `0${hh}` : hh}:${min < 10 ? `0${min}` : min}:${ss < 10 ? `0${ss}` : ss}.`;
  if (SSS < 10) {
    timestamp = `${timestamp}00`;
  } else if (SSS < 100) {
    timestamp = `${timestamp}0`;
  }
  timestamp = `${timestamp}${SSS}`;
  return timestamp;
}

export function SendStats(stats: StatJSON): void {
  stats.date = GetDate();
  stats.entrypoint_ip = window.location.host;
  stats.url = window.location.href;
  stats.isWebApp = store.getters.mobile.isWebApp;
  axios.post("/send_stat/gamer", stats);
}

export function SentRatingStats(stats: {
  webrtc_session_id: string;
  game: string;
  stat_id: string;
  username: string | undefined;
  rating: number;
  issues: string[];
}): void {
  const toto: StatRating = {
    ...stats,
    date: GetDate(),
    entrypoint_ip: window.location.host,
    url: window.location.href,
    log_type: "client_rating_stat",
  };
  SendStats(toto);
}
