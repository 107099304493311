import { defineModule } from "direct-vuex";
import { UAParser } from "ua-parser-js";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";

import { moduleActionContext } from "@/store";

import { Manifest } from "@/interfaces/manifest";

export enum OrientationMode {
  PORTRAIT,
  LANDSCAPE,
}

const localStorageKeyNoMoreTuto = "noMoreTuto";
const localStorageKeyNoMoreFabHelperText = "noMoreFabHelperText";
const localStorageKeyNoMoreHomeTour = "noMoreHomeTour";
const parser = new UAParser();

export interface MobileState {
  orientation: OrientationMode;
  noMoreTuto: boolean;
  noMoreFabHelperText: boolean;
  noMoreHomeTour: boolean;
  manifest: Manifest | undefined;
}

const module = defineModule({
  namespaced: true as const,
  state: {
    orientation: OrientationMode.PORTRAIT,
    noMoreTuto: localStorage.getItem(localStorageKeyNoMoreTuto) === "true",
    noMoreFabHelperText: localStorage.getItem(localStorageKeyNoMoreFabHelperText) === "true",
    noMoreHomeTour: localStorage.getItem(localStorageKeyNoMoreHomeTour) === "true",
    manifest: undefined,
  } as MobileState,
  getters: {
    isWebApp: (): boolean =>
      window.navigator.standalone == true || window.matchMedia("(display-mode: standalone)").matches,
    isiOS: (): boolean => parser.getOS().name === "iOS",
    isAndroid: (): boolean => parser.getOS().name === "Android",
    isBrowserValid: (): boolean => {
      if (parser.getOS().name === "Android") {
        switch (parser.getBrowser().name) {
          case "Chrome": // Also matches Vivaldi
          case "Samsung Browser":
          case "Opera":
          case "Firefox":
          case "Edge":
          case "Facebook":
          case "Chrome WebView":
            return true;
          default:
            return false;
        }
      }
      if (parser.getOS().name === "iOS") {
        switch (parser.getBrowser().name) {
          case "Mobile Safari":
            return true;
          default:
            return false;
        }
      }
      return false;
    },
  },
  mutations: {
    SET_ORIENTATION(state) {
      state.orientation = window.innerHeight > window.innerWidth ? OrientationMode.PORTRAIT : OrientationMode.LANDSCAPE;
    },
    SET_NO_MORE_TUTO(state, noMoreTuto: boolean) {
      state.noMoreTuto = noMoreTuto;
      localStorage.setItem(localStorageKeyNoMoreTuto, noMoreTuto.toString());
    },
    SET_NO_MORE_FAB_HELPER_TEXT(state, noMoreFabHelperText: boolean) {
      state.noMoreFabHelperText = noMoreFabHelperText;
      localStorage.setItem(localStorageKeyNoMoreFabHelperText, noMoreFabHelperText.toString());
    },
    SET_NO_MORE_HOME_TOUR(state, noMoreHomeTour: boolean) {
      state.noMoreHomeTour = noMoreHomeTour;
      localStorage.setItem(localStorageKeyNoMoreHomeTour, noMoreHomeTour.toString());
    },
    SET_MANIFEST(state, manifest: Manifest) {
      state.manifest = mergeWith(state.manifest, manifest, (objValue, srcValue) =>
        isArray(objValue) ? srcValue : undefined
      );
      const stringManifest = JSON.stringify(state.manifest);
      const blob = new Blob([stringManifest], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector("#custom-manifest")?.setAttribute("href", manifestURL);
    },
  },
  actions: {
    init(context) {
      const { commit, dispatch } = moduleActionContext(context, module);
      commit.SET_ORIENTATION();
      window.addEventListener("resize", () => commit.SET_ORIENTATION());
      dispatch.updateAndSetManifest();
    },
    updateAndSetManifest(context) {
      const { commit, rootGetters } = moduleActionContext(context, module);

      const dynamicManifest: Manifest = {
        name: `CareGame`,
        short_name: "CareGame",
        description: "Mobile Gaming, One Click Away",
        display: "standalone",
        start_url: `${location.origin}/`,
        background_color: "#262323",
        theme_color: "#424242",
        icons: [
          {
            src: "/android-chrome-192x192.png",
            sizes: "192x192",
            type: "image/png",
          },
          {
            src: "/android-chrome-512x512.png",
            sizes: "512x512",
            type: "image/png",
          },
        ],
      };
      if (rootGetters.authentication.jwt) dynamicManifest.start_url += rootGetters.authentication.jwt;
      commit.SET_MANIFEST(dynamicManifest);
    },
  },
});

export default module;
