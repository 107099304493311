import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/src/locale/fr";

import {
  mdiHome,
  mdiGamepadVariant,
  mdiAccountCircleOutline,
  mdiClose,
  mdiFullscreen,
  mdiHeart,
  mdiHeartPlusOutline,
  mdiHelpCircle,
  mdiExclamationThick,
  mdiMenu,
  mdiVolumeHigh,
  mdiVolumeMute,
  mdiWifiStrength1,
  mdiWifiStrength2,
  mdiWifiStrength3,
  mdiWifiStrength4,
  mdiWifiStrengthOutline,
  mdiPowerStandby,
  mdiCounter,
  mdiGoogleController,
  mdiAccountCircle,
  mdiAccountSettings,
  mdiHeartHalfFull,
  mdiAndroid,
  mdiGoogleChrome,
  mdiApple,
  mdiAppleSafari,
  mdiHomePlus,
  mdiScreenRotation,
  mdiCalendar,
  mdiChevronLeft,
  mdiPlay,
  mdiAccount,
  mdiLockQuestion,
  mdiEye,
  mdiEyeOff,
  mdiEmail,
  mdiLock,
  mdiPlayCircle,
  mdiPencil,
  mdiClock,
  mdiHelp,
  mdiHelpCircleOutline,
  mdiAccountCog,
  mdiOpenInNew,
  mdiLogout,
  mdiPlus,
  mdiMinus,
  mdiCheck,
  mdiClockOutline,
} from "@mdi/js";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      account: mdiAccount,
      accountCircle: mdiAccountCircle,
      accountCircleOutline: mdiAccountCircleOutline,
      accountCog: mdiAccountCog,
      accountSettings: mdiAccountSettings,
      android: mdiAndroid,
      apple: mdiApple,
      appleSafari: mdiAppleSafari,
      calendar: mdiCalendar,
      chevronLeft: mdiChevronLeft,
      clock: mdiClock,
      close: mdiClose,
      counter: mdiCounter,
      email: mdiEmail,
      exclamationThick: mdiExclamationThick,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      fullscreen: mdiFullscreen,
      gamepadVariant: mdiGamepadVariant,
      googleChrome: mdiGoogleChrome,
      googleController: mdiGoogleController,
      heart: mdiHeart,
      heartHalfFull: mdiHeartHalfFull,
      heartPlusOutline: mdiHeartPlusOutline,
      help: mdiHelp,
      helpCircle: mdiHelpCircle,
      helpCircleOutline: mdiHelpCircleOutline,
      home: mdiHome,
      homePlus: mdiHomePlus,
      lock: mdiLock,
      lockQuestion: mdiLockQuestion,
      logout: mdiLogout,
      menu: mdiMenu,
      openInNew: mdiOpenInNew,
      pencil: mdiPencil,
      play: mdiPlay,
      playCircle: mdiPlayCircle,
      powerStandby: mdiPowerStandby,
      screenRotation: mdiScreenRotation,
      volumeHigh: mdiVolumeHigh,
      volumeMute: mdiVolumeMute,
      wifiStrength1: mdiWifiStrength1,
      wifiStrength2: mdiWifiStrength2,
      wifiStrength3: mdiWifiStrength3,
      wifiStrength4: mdiWifiStrength4,
      wifiStrengthOutline: mdiWifiStrengthOutline,
      plus: mdiPlus,
      minus: mdiMinus,
      check: mdiCheck,
      clockOutline: mdiClockOutline,
    },
  },
  theme: {
    dark: true,
  },
  lang: {
    current: "fr",
    locales: { fr },
  },
});
