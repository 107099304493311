



















import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import TheConnectionLogin from "@/components/TheConnectionLogin.vue";
import TheConnectionRegister from "@/components/TheConnectionRegister.vue";
import TheConnectionForgotPassword from "@/components/TheConnectionForgotPassword.vue";

enum State {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
}

@Component({
  components: {
    TheConnectionLogin,
    TheConnectionRegister,
    TheConnectionForgotPassword,
  },
})
export default class TheConnectionPopup extends Vue {
  state: State = State.LOGIN;
  State = State;

  @Watch("$store.direct.state.loginPopup.show")
  onVisibilityChange(): void {
    this.state = State.LOGIN;
  }

  get show(): boolean {
    return this.$store.direct.state.loginPopup.show;
  }

  close(): void {
    this.$store.direct.commit.CLOSE_LOGIN_POPUP();
  }
}
