















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

@Component
export default class Email extends Vue {
  @Prop(String)
  value = "";

  get email(): string {
    return this.value;
  }

  set email(newValue: string) {
    this.$emit("input", newValue);
  }

  // eslint-disable-next-line no-control-regex
  regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  rules: Array<(v: string) => boolean | string> = [
    (v: string): boolean | string => !!v || i18n.t("connection.register.form.validation.email.required").toString(),
    (v: string): boolean | string =>
      this.regex.test(v) || i18n.t("connection.register.form.validation.email.invalid").toString(),
  ];
}
