import Vue from "vue";
import VueTour from "vue-tour";

import App from "@/App.vue";
import "@/registerServiceWorker";

import router from "@/router";
import store from "@/store";

import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import * as LogRocket from "./plugins/LogRocket";

LogRocket.init();

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, _vm, _info) {
  console.error(err);
  LogRocket.captureException(err);
};

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

new Vue({
  router,
  store: store.original,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
