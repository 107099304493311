






















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import { axiosInstance as axios } from "@/store/";

import Email from "@/components/inputs/Email.vue";

@Component({
  components: {
    Email,
    VueHcaptcha,
  },
})
export default class TheConnectionForgotPassword extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  captchaToken = "";

  email = "";

  $refs!: {
    form: HTMLFormElement;
  };

  async submit(): Promise<void> {
    if (this.$refs.form.validate() && this.captchaToken) {
      this.sending = true;
      try {
        await axios.post(
          `/auth/user/passwordreset`,
          {
            email: this.email,
            token: this.captchaToken,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        this.$store.direct.commit.SHOW_TOAST({
          text: this.$t("connection.forgotPassword.notifications.emailSent").toString(),
        });
        this.$store.direct.commit.CLOSE_LOGIN_POPUP();
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("connection.forgotPassword.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 404)
            this.alert.message = this.$t("connection.forgotPassword.form.error.code404").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }

  get hcaptchaSiteKey(): string {
    return process.env.VUE_APP_HCPATCHA_SITE_KEY;
  }
}
