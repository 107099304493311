





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class AlertDialog extends Vue {
  @Prop(Boolean) dialog: boolean;

  @Prop({ type: String }) title: string;
  @Prop({ type: String }) text: string;
  @Prop({ type: String, default: "Ok" }) buttonText: string;

  get show(): boolean {
    return this.dialog;
  }

  set show(value: boolean) {
    this.$emit("close");
  }
}
