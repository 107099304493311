

















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class WelcomePopup extends Vue {
  @Prop(Boolean) show: boolean;

  get username(): string {
    return this.$store.direct.state.authentication.user?.username || "";
  }
}
