import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import { RawLocation } from "vue-router";
import axios from "axios";

import authentication from "./modules/authentication";
import language from "./modules/language";
import games from "./modules/games";
import mobile from "./modules/mobile";

Vue.use(Vuex);

interface LoginPopup {
  show: boolean;
  showRegister: boolean;
  onLogin?: () => void;
}

interface MigrateAccountPopup {
  show: boolean;
}

interface AlertDialog {
  show: boolean;
  title?: string;
  text: string;
  buttonText?: string;
  onClose?: () => void;
}

interface Toast {
  show: boolean;
  text: string;
}

export interface RootState {
  apiEndpoint: string;
  isLoading: boolean;
  requestedRoute: RawLocation;
  alert: AlertDialog;
  toast: Toast;
  loginPopup: LoginPopup;
  migrateAccountPopup: MigrateAccountPopup;
  showWrongBrowserPopup: boolean;
}

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    authentication,
    language,
    games,
    mobile,
  },
  state: {
    apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    isLoading: false,
    requestedRoute: "",
    alert: {
      show: false,
    },
    toast: {
      show: false,
    },
    loginPopup: {
      show: false,
      showRegister: true,
    },
    migrateAccountPopup: {
      show: false,
    },
    showWrongBrowserPopup: false,
  } as RootState,
  getters: {},
  mutations: {
    SET_LOADING(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    SET_REQUESTED_ROUTE(state, requestedRoute: RawLocation) {
      state.requestedRoute = requestedRoute;
    },
    SHOW_ALERT(state, { title, text, onClose }: { title?: string; text: string; onClose?: () => void }) {
      state.alert.title = title;
      state.alert.text = text;
      state.alert.onClose = onClose;
      state.alert.show = true;
    },
    CLOSE_ALERT(state) {
      state.alert.onClose?.();
      state.alert.show = false;
    },
    SHOW_TOAST(state, { text }: { text: string }) {
      state.toast.text = text;
      state.toast.show = true;
    },
    CLOSE_TOAST(state) {
      state.toast.show = false;
    },
    SHOW_LOGIN_POPUP(state, { onLogin, showRegister = true }: { onLogin?: () => void; showRegister?: boolean } = {}) {
      state.loginPopup.onLogin = onLogin;
      state.loginPopup.showRegister = showRegister;
      state.loginPopup.show = true;
    },
    CLOSE_LOGIN_POPUP(state, { logged = false }: { logged?: boolean } = {}) {
      if (logged) state.loginPopup.onLogin?.();
      state.loginPopup.show = false;
    },
    SHOW_MIGRATE_ACCOUNT_POPUP(state) {
      state.migrateAccountPopup.show = true;
    },
    CLOSE_MIGRATE_ACCOUNT_POPUP(state) {
      state.migrateAccountPopup.show = false;
    },
    SHOW_ZENDESK() {
      window.zE.activate();
    },
    SHOW_WRONG_BROWSER_POPUP(state) {
      state.showWrongBrowserPopup = true;
    },
  },
  actions: {},
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  config.baseURL = store.state.apiEndpoint;
  const token = store.getters.authentication.jwt;
  if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 429) {
    // TODO Show popup to tell people that they sent too many requests?
  }
  return Promise.reject(error);
});
