

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

@Component
export default class Password extends Vue {
  @Prop(String)
  value = "";

  @Prop({ type: Boolean, default: false })
  new: boolean;

  @Prop({ type: Array, default: () => [] })
  rules: Array<(v: string) => boolean | string>;

  @Prop({ type: Boolean, default: true })
  overrideRules: boolean;

  get totalRules(): Array<(v: string) => boolean | string> {
    if (this.overrideRules) return this.rules.length > 0 ? this.rules : this.internalRules;
    else return this.internalRules.concat(this.rules);
  }

  get password(): string {
    return this.value;
  }

  set password(newValue: string) {
    this.$emit("input", newValue);
  }

  get autocomplete(): string {
    return this.new ? "new-password" : "current-password";
  }

  show = false;

  internalRules: Array<(v: string) => boolean | string> = [
    (v: string): boolean | string => !!v || i18n.t("connection.register.form.validation.password.required").toString(),
  ];
}
