













































import { axiosInstance } from "@/store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class AccountSuspendedPopup extends Vue {
  @Prop(Boolean) show: boolean;
  @Prop(String) login: string;
  @Prop(String) password: string;

  disabled = false;

  async send(): Promise<void> {
    await axiosInstance.post("auth/user/resendconfirmaccounttoken", {
      login: this.login,
      password: this.password,
    });
    this.disabled = true;
    this.$store.direct.commit.SHOW_TOAST({ text: this.$t("accountSuspended.notifications.emailSent").toString() });
    this.$emit("close");
  }
}
