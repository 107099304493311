



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import LoginForm from "@/components/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
})
export default class AccountLimitReachedPopup extends Vue {
  @Prop(Boolean) show: boolean;

  logged(): void {
    this.$emit("close");
    this.$router.replace("/");
  }
}
