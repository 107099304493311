

























































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import debounce from "lodash/debounce";

import i18n from "@/plugins/i18n";

import AlertDialog from "@/components/AlertDialog.vue";
import PlayOverlay from "@/components/PlayOverlay.vue";
import TheToast from "@/components/TheToast.vue";
import TheConnectionPopup from "@/components/TheConnectionPopup.vue";
import GetTempAccountPopup from "@/components/GetTempAccountPopup.vue";
import WrongBrowserPopup from "@/components/WrongBrowserPopup.vue";
import AccountLimitReachedPopup from "@/components/AccountLimitReachedPopup.vue";
import MigrateAccountPopup from "@/components/MigrateAccountPopup.vue";
import NeedAccessPopup from "@/components/NeedAccessPopup.vue";

import { axiosInstance } from "./store";

@Component({
  name: "App",
  components: {
    AlertDialog,
    PlayOverlay,
    TheToast,
    TheConnectionPopup,
    GetTempAccountPopup,
    WrongBrowserPopup,
    AccountLimitReachedPopup,
    MigrateAccountPopup,
    NeedAccessPopup,
  },
})
export default class App extends Vue {
  showGetTempAccountPopup = false;
  showWrongBrowserPopup = false;
  showAccountLimitReachedPopup = false;
  showNeedAccessPopup = false;

  get shouldKeepAlive(): RegExp | string {
    const shouldKeepAlive = process.env.NODE_ENV === "production";
    return shouldKeepAlive ? "" : /.+/;
  }

  get showPlsRotate(): boolean {
    return (
      !["Home", "ResetPassword", "Play", "Profile", "GameList", "GenreGames", "Game", "Account"].includes(
        this.$route.name || ""
      ) && this.$store.direct.state.mobile.orientation === 1
    );
  }

  @Watch("showPlsRotate")
  OnShowPlsRotateChange(newValue: boolean): void {
    if (newValue) document.querySelector("html")?.classList.add("noscroll");
    else document.querySelector("html")?.classList.remove("noscroll");
  }

  async handleRouterParameters(): Promise<void> {
    const store = this.$store.direct;

    if (this.$route.params.cgtoken) await store.dispatch.authentication.setJWT(this.$route.params.cgtoken as string);
    if (this.$route.query.cgtoken) await store.dispatch.authentication.setJWT(this.$route.query.cgtoken as string);
    if (!store.getters.authentication.isAuthenticated) await store.dispatch.authentication.whoami();
  }

  async waitForRouterReady(): Promise<void> {
    return new Promise((res, _rej) => {
      this.$router.onReady(res, (err) => {
        console.warn(err);
        res();
      });
    });
  }

  async created(): Promise<void> {
    this.dostuff();
    this.$store.direct.commit.SET_LOADING(true);
    await this.waitForRouterReady();
    await this.handleRouterParameters();

    const promises = [];
    promises.push(this.$store.direct.dispatch.language.init());
    promises.push(this.$store.direct.dispatch.mobile.init());

    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      return;
    }

    axiosInstance.get("/server-status").catch((err) => {
      if (!err.response) {
        this.$store.direct.commit.SHOW_ALERT({
          title: i18n.t("maintenance-alert.title").toString(),
          text: i18n.t("maintenance-alert.text").toString(),
          onClose: () => {
            location.reload();
          },
        });
        return;
      }
    });

    if (!this.$store.direct.getters.authentication.isAuthenticated)
      await this.$store.direct.dispatch.authentication.whoami();

    if (!this.$store.direct.getters.authentication.isAuthenticated) {
      try {
        await axiosInstance.get("/auth/user/checklimit");
      } catch (err) {
        this.showAccountLimitReachedPopup = true;
        return;
      }
    }

    await this.$store.direct.dispatch.games.init();

    if (!this.$store.direct.getters.authentication.isAuthenticated || this.$store.direct.state.games.games.length == 0)
      this.showNeedAccessPopup = true;

    await Promise.all(promises);

    this.$store.direct.commit.SET_LOADING(false);
  }

  dostuff(): void {
    this.setViewHeight();

    const debouncedSetHeight = debounce(this.setViewHeight, 50);

    window.addEventListener("resize", debouncedSetHeight);

    this.$once("destroyed", () => {
      window.removeEventListener("resize", debouncedSetHeight);
    });
  }

  setViewHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
}
