



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import i18n from "@/plugins/i18n";
import { axiosInstance as axios } from "@/store/";

@Component
export default class LoginNew extends Vue {
  @Prop(String)
  value = "";

  get login(): string {
    return this.value;
  }

  set login(newValue: string) {
    this.$emit("input", newValue);
  }

  rules: Array<(v: string) => boolean | string> = [
    (v: string): boolean | string => !!v || i18n.t("connection.register.form.validation.login.required").toString(),
    (v: string): boolean | string =>
      v.length >= 3 || i18n.t("connection.register.form.validation.login.toosmall").toString(),
  ];

  alreadyUsedError = "";
  timeout?: number;
  loading = false;
  checkUsage(): void {
    this.alreadyUsedError = "";

    if (this.rules.some((a) => a(this.login) !== true)) return;
    this.alreadyUsedError = " ";
    this.loading = true;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      try {
        await axios.post(
          `/auth/user/loginvalidity`,
          {
            login: this.login,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        this.alreadyUsedError = "";
      } catch (err) {
        this.alreadyUsedError = i18n.t("connection.register.form.validation.login.alreadyUsed").toString();
      } finally {
        this.loading = false;
      }
    }, 1000);
  }
}
