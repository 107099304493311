


















import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class TheToast extends Vue {
  get show(): boolean {
    return this.$store.direct.state.toast.show;
  }

  set show(value: boolean) {
    if (!value) this.$store.direct.commit.CLOSE_TOAST();
  }
}
