





































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import format from "date-fns/format";
import subYears from "date-fns/subYears";

@Component
export default class GetTempAccountPopup extends Vue {
  @Prop(Boolean) show: boolean;

  birthDate = "";
  menu = false;
  loading = false;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  $refs!: {
    menu: any;
    picker: any;
  };

  get maxBirthDate(): string {
    return format(subYears(new Date(), 3), "yyyy-MM-dd");
  }

  async submitBirthDate(): Promise<void> {
    if (!this.birthDate) return;
    try {
      this.loading = true;
      await this.$store.direct.dispatch.authentication.getTempAccount(new Date(this.birthDate));
      this.$emit("close");
      this.$router.replace("/");
    } catch (e) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("guestAccountCreation.form.alert.error").toString(),
      });
    } finally {
      this.loading = false;
    }
  }

  goLogin(): void {
    this.$store.direct.commit.SHOW_LOGIN_POPUP({
      showRegister: false,
      onLogin: async () => {
        this.$emit("close");

        this.$router.replace("/");
      },
    });
  }

  @Watch("menu")
  onMenu(val: any): void {
    val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
  }

  save(birthDate: Date): void {
    this.$refs.menu.save(birthDate);
  }
}
