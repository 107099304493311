import Vue from "vue";
import VueRouter, { Route, RouterOptions, RawLocation, Location } from "vue-router";

import Store from "@/store/";
import * as Stats from "@/old-client-stuff/Stat";
import routes from "@/router/routes";

Vue.use(VueRouter);

class MyRouter extends VueRouter {
  private routeHistory: Route[] = [];
  private get hasMoved(): boolean {
    return this.routeHistory.length > 1;
  }

  constructor(options?: RouterOptions) {
    super(options);

    this.afterEach((to, _from) => {
      this.routeHistory.push(to);
    });
  }

  public back(fallback: RawLocation = "/"): void {
    this.hasMoved ? this.go(-1) : this.push(fallback);
  }
}

const router = new MyRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, _from, next) => {
  if (!Store.getters.mobile.isBrowserValid) {
    Store.commit.SHOW_WRONG_BROWSER_POPUP();
    Store.commit.SET_LOADING(false);
    next(false);
  } else next();
});

router.beforeEach(async (to, _from, next) => {
  if (to.params.cgtoken) await Store.dispatch.authentication.setJWT(to.params.cgtoken);
  if (to.query.cgtoken) await Store.dispatch.authentication.setJWT(to.query.cgtoken as string);
  if (!Store.getters.authentication.isAuthenticated) await Store.dispatch.authentication.whoami();
  if (Store.state.games.games.length == 0) await Store.dispatch.games.getGames();
  next();
});

router.beforeEach(async (to, _from, next) => {
  if (
    to.matched.some((record) => record.meta.accountValidatedRequired) &&
    !Store.state.authentication.user?.accountValidated
  ) {
    next("/");
  } else next();
});

router.beforeEach((to, _from, next) => {
  window.zE("webWidget", "updatePath", {
    title: to.name,
    url: to.fullPath,
  });
  next();
});

function hasQueryParams(route: Route): boolean {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (to.path === from.path) return next();
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    const location: Location = { query: from.query };
    next(Object.assign({}, to, location));
  } else next();
});

router.afterEach((to, from) => {
  const stat: Stats.StatNavigation = {
    date: Stats.GetDate(),
    entrypoint_ip: window.location.host,
    log_type: "navigation_stat",
    url: window.location.href,
    from: from.fullPath,
    to: to.fullPath,
    username: Store.state.authentication.user?.username,
    stat_id: "",
  };
  Stats.SendStats(stat);
});

router.afterEach(() => {
  Store.dispatch.mobile.updateAndSetManifest();
});

export default router;
