import { RouteConfig } from "vue-router";

interface MyRouteConfigInterface {
  meta?: {
    authRequired?: boolean;
    accountValidatedRequired?: boolean;
    skipTempAccount?: boolean;
    zendeskSearchTerms?: string[];
  };
}

type MyRouteConfig = MyRouteConfigInterface & RouteConfig;

const routes: MyRouteConfig[] = [
  {
    path: "/game/:game",
    name: "Game",
    component: () => import("@/views/Game.vue"),
    meta: {
      authRequired: true,
      zendeskSearchTerms: ["jeu", "jeux", "jouer"],
    },
  },
  {
    path: "/play/:game",
    name: "Play",
    component: () => import("@/views/Play.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/games/:segment/:sort?",
    name: "GameList",
    component: () => import("@/views/GameList.vue"),
    meta: {
      authRequired: true,
      zendeskSearchTerms: ["jeu", "jeux"],
    },
  },
  {
    path: "/genre/:genre/:sort?",
    name: "GenreGames",
    component: () => import("@/views/GameList.vue"),
    meta: {
      authRequired: true,
      zendeskSearchTerms: ["jeu", "jeux"],
    },
  },
  {
    path: "/profile/:tab?",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account.vue"),
    meta: {
      authRequired: true,
      accountValidatedRequired: true,
    },
  },
  {
    path: "/token/:token",
    alias: ["/confirmAccount/:token", "/confirmUpdateMail/:token", "/confirmDelete/:token"],
    name: "Token",
    component: () => import("@/views/Token.vue"),
  },
  {
    path: "/passwordReset/:token",
    name: "ResetPassword",
    component: () => import("@/views/PasswordReset.vue"),
    meta: {
      skipTempAccount: true,
      zendeskSearchTerms: ["mot de passe", "password"],
    },
  },
  {
    path: "/:cgtoken?",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      authRequired: true,
      zendeskSearchTerms: ["caregame"],
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
  },
];

export default routes;
