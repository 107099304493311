import { defineModule } from "direct-vuex";

import * as LogRocket from "@/plugins/LogRocket";
import { moduleActionContext, moduleGetterContext, axiosInstance as axios } from "@/store/";
import router from "@/router";

export enum UserPermission {
  SEE_BASIC_WEBRTC_STATS = "SEE_BASIC_WEBRTC_STATS",
  SEE_FULL_WEBRTC_STATS = "SEE_FULL_WEBRTC_STATS",
  SKIP_ADD2HOME = "SKIP_ADD2HOME",
}

interface UserJWTPayload {
  id: string;
  login: string;
  email: string;
  favoriteGames: string[];
  lastPlayedGames: string[];
  permissions: UserPermission[];
  temporaryAccount: boolean;
  accountValidated: boolean;
  language: string;
  birthDate: Date;
}

export interface User {
  id: string;
  username: string;
  email: string;
  permissions: UserPermission[];
  temporaryAccount: boolean;
  accountValidated: boolean;
  language: string;
  birthDate: Date;
}

export interface AuthenticationState {
  user: User | null;
  jwt: string | undefined;
}

const TOKEN_KEY = "token";
const CACHE_KEY = "auth";
let cacheStorage: Cache | undefined;

const module = defineModule({
  namespaced: true as const,
  state: {
    user: null,
    jwt: undefined,
  } as AuthenticationState,
  getters: {
    isAuthenticated: (state): boolean => !!state.user,
    jwt: (state): string | undefined => state.jwt || localStorage.getItem(TOKEN_KEY) || undefined,
    /**
     * Get the JWT token
     */
    token: (...args): string | undefined => {
      const { getters } = moduleGetterContext(args, module);
      return getters.jwt;
    },
    hasPermission: (state) => (permission: UserPermission): boolean =>
      state.user?.permissions.includes(permission) || false,
    isTemporaryAccount: (state): boolean => !!state.user?.temporaryAccount,
    age: (state): number => {
      if (!state.user?.birthDate) return Number.MAX_SAFE_INTEGER;
      const today = new Date();

      let age = today.getFullYear() - state.user?.birthDate.getFullYear();
      const m = today.getMonth() - state.user?.birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < state.user?.birthDate.getDate())) age--;

      return age;
    },
  },
  mutations: {
    SET_JWT(state: AuthenticationState, jwt: string) {
      state.jwt = jwt;
      localStorage.setItem(TOKEN_KEY, jwt);
    },
    DELETE_JWT(state: AuthenticationState) {
      state.jwt = undefined;
      localStorage.removeItem(TOKEN_KEY);
      cacheStorage?.delete(TOKEN_KEY);
    },
    SET_USER(state: AuthenticationState, user: User | null) {
      state.user = user;
    },
  },
  actions: {
    async login(context, { login, password }: { login: string; password: string }) {
      const { dispatch, rootDispatch } = moduleActionContext(context, module);

      const response = await axios.post(
        `/auth/user/login`,
        {
          login,
          password,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.data.token) throw new Error("Didn't get a JWT");
      await dispatch.setJWT(response.data.token);
      await dispatch.whoami();
      await rootDispatch.games.init();
    },
    logout(context) {
      const { commit } = moduleActionContext(context, module);
      commit.SET_USER(null);
      commit.DELETE_JWT();
      window.zE("webWidget", "logout");
    },
    async whoami(context) {
      const { commit, state, getters, dispatch, rootCommit } = moduleActionContext(context, module);

      await dispatch.initCacheStorage();
      if (!getters.jwt) return;
      try {
        const response = await axios.get<UserJWTPayload>(`/auth/user/me`);
        commit.SET_USER({
          id: response.data.id,
          username: response.data.login,
          email: response.data.email,
          permissions: response.data.permissions,
          temporaryAccount: response.data.temporaryAccount,
          accountValidated: response.data.accountValidated,
          language: response.data.language,
          birthDate: new Date(response.data.birthDate),
        });
        rootCommit.games.SET_FAVORITE_GAMES(response.data.favoriteGames);
        rootCommit.games.SET_RECENT_GAMES(response.data.lastPlayedGames);
        LogRocket.identify(response.data.id, {
          name: response.data.login,
        });
        /*
        if (!state.user?.birthDate || !state.user?.language) {
          rootCommit.SHOW_MIGRATE_ACCOUNT_POPUP();
        }
        */
        if (state.user?.accountValidated) {
          window.zE("webWidget", "updateSettings", {
            webWidget: {
              chat: {
                suppress: false,
              },
              contactForm: {
                suppress: false,
              },
            },
          });
          window.zE("webWidget", "identify", {
            name: response.data.login,
            email: response.data.email,
          });
        }
      } catch (e) {
        if (e.response.status === 403) {
          dispatch.logout();
        }
      }
    },
    async getTempAccount(context, birthDate: Date) {
      const { dispatch, rootDispatch, rootState } = moduleActionContext(context, module);

      const response = await axios.post(
        `/auth/user/temp`,
        {
          birthDate,
          language: rootState.language.language,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (!response.data.token) throw new Error("Didn't get a JWT");
      await dispatch.setJWT(response.data.token);
      await dispatch.whoami();
      await rootDispatch.games.init();
    },
    async register(
      context,
      { email, login, password, captchaToken }: { email: string; login: string; password: string; captchaToken: string }
    ) {
      const { dispatch, rootDispatch } = moduleActionContext(context, module);

      await axios.post(
        `/auth/user/register`,
        {
          email,
          login,
          password,
          token: captchaToken,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      await dispatch.whoami();
      await rootDispatch.games.init();
    },
    async initCacheStorage(context): Promise<void> {
      const { commit } = moduleActionContext(context, module);

      if (!window.caches) return;
      if (!cacheStorage) cacheStorage = await caches.open(CACHE_KEY);
      const cacheResponse = await cacheStorage.match(TOKEN_KEY);
      if (cacheResponse) {
        const token: string = await cacheResponse.json();
        commit.SET_JWT(token);
      }
    },
    async getZendeskJWT(): Promise<string> {
      const response = await axios.get(`/auth/user/zendesk-jwt`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!response.data.token) throw new Error("Didn't get a JWT");
      return response.data.token;
    },
    async setJWT(context, jwt: string) {
      const { commit } = moduleActionContext(context, module);
      commit.SET_JWT(jwt);
      await cacheStorage?.put(TOKEN_KEY, new Response(JSON.stringify(jwt)));
    },
    async migrateOldAccount(
      context,
      { login, password, birthDate }: { login: string; password: string; birthDate: string }
    ) {
      const { dispatch, rootDispatch, rootState } = moduleActionContext(context, module);

      const response = await axios.put(`/auth/user/migrate-old-account`, {
        login,
        password,
        birthDate,
        language: rootState.language.language,
      });
      if (!response.data.token) throw new Error("Didn't get a JWT");
      await dispatch.setJWT(response.data.token);

      const params = Object.assign({}, router.currentRoute.params);
      delete params.authtoken;
      router.replace({ path: "/", params });

      await dispatch.whoami();
      await rootDispatch.games.init();
    },
  },
});

export default module;
