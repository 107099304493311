


















































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import format from "date-fns/format";
import subYears from "date-fns/subYears";

import i18n from "@/plugins/i18n";

import LoginNew from "@/components/inputs/LoginNew.vue";
import Password from "@/components/inputs/Password.vue";
import PasswordNew from "@/components/inputs/PasswordNew.vue";
import { AxiosError } from "axios";

@Component({
  components: {
    LoginNew,
    Password,
    PasswordNew,
  },
})
export default class MigrateAccountPopup extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  username = "";
  password = "";
  passwordRepeat = "";
  birthDate = "";

  passwordRepeatRules = [
    (v: string): boolean | string =>
      !!v || i18n.t("connection.register.form.validation.passwordRepeat.required").toString(),
    (_v: string): boolean | string =>
      this.checkPasswordRepeat() || i18n.t("connection.register.form.validation.passwordRepeat.notmatch").toString(),
  ];

  cguAcceptedRules = [
    (v: boolean): boolean | string => !!v || i18n.t("connection.register.form.validation.cgu.notaccepted").toString(),
  ];

  legalTutorAcceptedRules = [
    (v: boolean): boolean | string =>
      !!v || i18n.t("connection.register.form.validation.legalTutor.notaccepted").toString(),
  ];
  menu = false;

  checkPasswordRepeat(): boolean {
    return this.password === this.passwordRepeat;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  $refs!: {
    menu: any;
    picker: any;
    form: HTMLFormElement;
  };

  get maxBirthDate(): string {
    return format(subYears(new Date(), 3), "yyyy-MM-dd");
  }

  async submitBirthDate(): Promise<void> {
    if (!this.birthDate) return;
    await this.$store.direct.dispatch.authentication.getTempAccount(new Date(this.birthDate));
    this.$emit("close");
    this.$router.replace("/");
  }

  get age(): number {
    if (!this.birthDate) return Number.MAX_SAFE_INTEGER;
    const today = new Date();
    const birthDate = new Date(this.birthDate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    return age;
  }

  async submit(): Promise<void> {
    if (this.$refs.form.validate() && this.birthDate) {
      this.sending = true;
      try {
        await this.$store.direct.dispatch.authentication.migrateOldAccount({
          login: this.username,
          password: this.password,
          birthDate: this.birthDate,
        });
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("connection.register.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 403)
            this.alert.message = this.$t("connection.register.form.error.generic").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
      this.$store.direct.commit.CLOSE_MIGRATE_ACCOUNT_POPUP();
    }
  }

  @Watch("menu")
  onMenu(val: any): void {
    val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
  }

  save(birthDate: Date): void {
    this.$refs.menu.save(birthDate);
  }

  get show(): boolean {
    return this.$store.direct.state.migrateAccountPopup.show;
  }

  close(): void {
    this.$store.direct.commit.CLOSE_MIGRATE_ACCOUNT_POPUP();
  }
}
